import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/games',
    name: 'Games',
    component: () => import('../views/GamesView.vue')
  },
  {
    path: '/programs',
    name: 'Programs',
    component: () => import('../views/ProgramsView.vue')
  },
  {
    path: '/projects',
    name: 'Hobby Projects',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    path: '/cat',
    name: 'Cat',
    component: () => import('../views/CatView.vue')
  },
  {
    path: '/server',
    name: 'Server',
    component: () => import('../views/ServerView.vue')
  },
  {
    path: '/gurps',
    name: 'Gurps',
    component: () => import('../views/GurpsView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
