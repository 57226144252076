<template>
<div class="header">
  <h1>Mikael Wiik</h1>
  <nav>
    | <span v-for="m in menuItems" :key="m.id" >
      <router-link :to="m.path">{{m.name}}</router-link> |
    </span>
  </nav>
</div>
<div class="router-div">
  <router-view/>
</div>
</template>

<script>
import { routes } from "./router"
export default {
  data() {
    let id = 1;
    const menuItems = routes.map(r => {
      return {
        id: id++,
        name: r.name,
        path: r.path,
      }
    });
    return {
      menuItems,
    }
  },
}
</script>

<style>
#app {
  font-family: IBM Plex Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
}

#app > div {
  background-color: #FFFFFF0D;
  box-shadow: -2px 2px 4px 0 #00000070;
  border-radius: 0.25rem;
}

body {
  background-color: #121212;
}

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
}

.header > h1 {
  padding: 8px 0 0 0;
  margin-bottom: 0px;
}

nav {
  padding: 20px 0 20px 0;
}

nav a {
  font-weight: bold;
  color: #3949AB;
}

nav a.router-link-exact-active {
  color: #9FA8DA;
}

.router-div {
  padding: 10px;
}

.router-div > div {
  text-align: center;
}

h1, h2 {
  color: #9FA8DA;
  margin: 0 0 10px;
}

</style>
