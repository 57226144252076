<template>
  <div class="home">
    <h2>Welcome to my Vue.js site</h2>
    <div>
      <p>Hi, my name is Mikael and I'm a backend/fullstack developer by education and a nerd / maker in my spare time.</p>
      <p>This site is primarily a showcase of my hobbies and things I have done and while it might not be the prettiest site out there I'm not a front-end expert and this whole thing (webserver + api + front-end) was set up over the course of a little over two weeks without any prior knowledge of how to set up a Vue.js project, configure a nginx server or deploy a .NET api to a Raspberry Pi.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style scoped>
.home > div{
  background-color: #FFFFFF0D;
  box-shadow: -2px 2px 4px 0 #00000070;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
</style>